import { useAppSelector, useAppDispatch } from '@/lib/redux/hooks'
import { setSelectedNode } from '@/modules/canvas/canvasSlice'
import { Component, Scan, Type } from 'lucide-react'

export default function TreePanel() {
	const rootNodes = useAppSelector((s) => s.content.rootNodes)
	const showInterface = useAppSelector((s) => s.preferences.showInterface)

	return (
		showInterface && (
			<div className='w-60 z-40 bg-white border-r border-zinc-200 h-full shrink-0 flex flex-col'>
				<h3 className='text-sm font-medium px-4 py-3 border-b border-zinc-200 shrink-0'>
					Nodes
				</h3>
				<div className='overflow-y-auto grow'>
					{rootNodes.map((nodeId) => (
						<NodeItemContainer key={nodeId} level={1} nodeId={nodeId} />
					))}
				</div>
			</div>
		)
	)
}

const NodeItemContainer = (props: { level: number; nodeId: NodeId }) => {
	const dispatch = useAppDispatch()
	const node = useAppSelector((s) => s.content.nodes[props.nodeId])
	const childrenIds = 'childrenIds' in node ? node.childrenIds : []
	const isSelected = useAppSelector(
		(s) => s.canvas.selectedNodeId === props.nodeId
	)

	return (
		<>
			<NodeItem
				isSelected={isSelected}
				level={props.level}
				name={node.type === 'component' ? node.componentKey : node.name}
				type={node.type}
				onClick={() => dispatch(setSelectedNode({ id: props.nodeId }))}
				Icon={
					node.type === 'component' ? (
						<Component
							size={14}
							absoluteStrokeWidth
							strokeWidth={1}
							className='text-violet-600'
						/>
					) : node.type === 'frame' ? (
						<Scan size={13} absoluteStrokeWidth strokeWidth={1} />
					) : (
						<Type size={13} absoluteStrokeWidth strokeWidth={1} />
					)
				}
			/>
			{childrenIds.map((childId) => (
				<NodeItemContainer
					key={childId}
					level={props.level + 1}
					nodeId={childId}
				/>
			))}
		</>
	)
}

function NodeItem(props: {
	isSelected: boolean
	level: number
	name: string
	type: CanvasNodeType
	onClick: () => void
	Icon: React.ReactNode
}) {
	return (
		<button
			className={`flex items-center px-1.5 py-1.5 gap-2 text-sm w-full truncate ${
				props.isSelected ? 'bg-blue-500 text-white' : 'bg-primary-int'
			}`}
			onClick={props.onClick}
			style={{ paddingLeft: `${8 + props.level * 8}px` }}
		>
			<span className=''>{props.Icon}</span>
			<span className='pb-0.5'>{props.name}</span>
		</button>
	)
}

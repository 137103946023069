import { Label } from '@/components/ui/label'
import './editor-input.css'
import { cva } from 'class-variance-authority'
import { cn } from '@/lib/utils'
import { forwardRef } from 'react'

const inputStyles = cva(
	'flex h-8 w-full rounded-md bg-transparent text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
	{
		variants: {
			type: {
				numeric: 'tabular-nums',
				text: '',
			},
		},
	}
)

function InputContainer(props: { children: React.ReactNode }) {
	return (
		<div className='flex gap-2 items-center focus-within:bg-muted ring-zinc-200 px-2 rounded-sm'>
			{props.children}
		</div>
	)
}

function InitialLetterLabel(props: { label: string }) {
	return (
		<div className='w-6 text-center'>
			<Label className='text-sm font-medium text-zinc-400'>{props.label}</Label>
		</div>
	)
}

function InputLabel(props: { label: string | React.ReactNode }) {
	return (
		<Label className='text-sm font-medium text-zinc-400'>{props.label}</Label>
	)
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const TextInput = forwardRef<HTMLInputElement, InputProps>(
	({ className, type, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(inputStyles({ type: 'text' }), className)}
				ref={ref}
				{...props}
			/>
		)
	}
)
TextInput.displayName = 'Input'

type NumericInputProps = {
	value: number
	onChange: (n: number) => void
	min?: number
	max?: number
	step?: number
	disabled?: boolean
	className?: InputProps['className']
}

function NumericInput({ step = 1, ...props }: NumericInputProps) {
	return (
		<input
			className={cn(inputStyles({ type: 'numeric' }), props.className)}
			type='number'
			value={props.value}
			onChange={(e) => {
				const isValid = e.target.checkValidity()
				let newValue = parseFloat(e.target.value || '0')

				if (!isValid) {
					// not possible to parse into a number (e.g. characters)
					if (Number.isNaN(newValue)) newValue = props.value
					// too low
					else if (props.min && newValue < props.min) newValue = props.min
					// too high
					else if (props.max && newValue > props.max) newValue = props.max
					// all other cases - don't propagate the changes
					else return
				}

				// finally, update the value
				props.onChange(newValue)
			}}
			min={props.min}
			max={props.max}
			step={step}
			disabled={props.disabled}
			inputMode='numeric'
		/>
	)
}

const EditorInput = {
	NumericInput,
	TextInput,
	InputLabel,
	InitialLetterLabel,
	InputContainer,
}

export default EditorInput

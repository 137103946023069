import EditorInput from '@/components/EditorInput'
import { PanelContainer } from './ui/PanelUI'

export default function CornerPanel(props: {
	cornerRadius: number
	onChangeCornerRadius: (cornerRadius: number) => void
}) {
	return (
		<PanelContainer>
			<div className='grid grid-cols-2 gap-x-2 gap-y-1'>
				<EditorInput.InputContainer>
					<EditorInput.InitialLetterLabel label='C' />
					<EditorInput.NumericInput
						value={props.cornerRadius}
						onChange={(value) => {
							props.onChangeCornerRadius(value)
						}}
					/>
				</EditorInput.InputContainer>
			</div>
		</PanelContainer>
	)
}

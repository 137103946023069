'use client'

import React, { useState, useEffect, useRef } from 'react'
import { HexColorPicker } from 'react-colorful'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'
import EditorInput from '@/components/EditorInput'

interface ColorInputProps {
	selectedColor: string
	onColorSelected: (color: string) => void
}

export default function ColorInput(props: ColorInputProps) {
	const [color, setColor] = useState(props.selectedColor)
	const [inputValue, setInputValue] = useState(props.selectedColor)
	const [isValid, setIsValid] = useState(true)
	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		setColor(props.selectedColor)
		setInputValue(props.selectedColor)
	}, [props.selectedColor])

	const isValidColor = (color: string) => {
		return (
			/^#[0-9A-F]{6}$/i.test(color) ||
			/^[0-9A-F]{6}$/i.test(color) ||
			/^[0-9A-F]{3}$/i.test(color)
		)
	}

	const formatColor = (color: string) => {
		if (/^#[0-9A-F]{6}$/i.test(color)) return color
		if (/^[0-9A-F]{6}$/i.test(color)) return `#${color}`
		if (/^[0-9A-F]{3}$/i.test(color))
			return `#${color[0]}${color[0]}${color[1]}${color[1]}${color[2]}${color[2]}`
		return color
	}

	const handleColorChange = (newColor: string) => {
		setColor(newColor)
		setInputValue(newColor)
		props.onColorSelected(newColor)
	}

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value
		setInputValue(newValue)
		const formattedColor = formatColor(newValue)
		if (isValidColor(formattedColor)) {
			setIsValid(true)
			handleColorChange(formattedColor)
		} else {
			setIsValid(false)
		}
	}

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		event.preventDefault()
		const pastedText = event.clipboardData.getData('text')
		const formattedColor = formatColor(pastedText)
		if (isValidColor(formattedColor)) {
			setInputValue(formattedColor)
			handleColorChange(formattedColor)
		} else {
			setInputValue(pastedText)
			setIsValid(false)
		}
	}
	return (
		<Popover>
			<EditorInput.InputContainer>
				<PopoverTrigger asChild>
					<div
						className='w-5 h-5 rounded-sm border border-gray-300 shrink-0'
						style={{ backgroundColor: isValid ? color : '#FFFFFF' }}
						aria-hidden='true'
					/>
				</PopoverTrigger>
				{/* <EditorInput.InitialLetterLabel label='#' /> */}
				<EditorInput.TextInput
					id='color-input'
					ref={inputRef}
					type='text'
					value={inputValue.toUpperCase()}
					onChange={handleInputChange}
					onPaste={handlePaste}
					className={`min-w-16 ${isValid ? '' : 'border-red-500'}`}
					aria-invalid={!isValid}
					aria-describedby={!isValid ? 'color-input-error' : undefined}
				/>
			</EditorInput.InputContainer>
			{!isValid && (
				<p id='color-input-error' className='mt-1 text-xs text-red-500'>
					Please enter a valid color (e.g., #FF0000, FF0000, or F00)
				</p>
			)}
			<PopoverContent className='w-auto p-0' align='start'>
				<HexColorPicker color={color} onChange={handleColorChange} />
			</PopoverContent>
		</Popover>
	)
}

export function PanelContainer(props: { children: React.ReactNode }) {
	return <div className='p-2'>{props.children}</div>
}

export function PanelHeader(props: {
	title: string
	children: React.ReactNode
}) {
	return (
		<div className='pl-2 flex items-center'>
			<h4 className='text-sm font-semibold'>{props.title}</h4>
			<span className='grow' />
			{props.children}
		</div>
	)
}

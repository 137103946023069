import { MousePointer2, Scan, Search, Type } from 'lucide-react'
import { setActiveTool } from '@/modules/canvas/canvasSlice'
import { toggleShowInterface } from '@/modules/preferences/preferences-slice'
import { useHotkeys } from 'react-hotkeys-hook'
import { useAppDispatch, useAppSelector } from '@/lib/redux/hooks'
import { pressureTest, resetCanvas } from '@/modules/content/contentSlice'

export default function Toolbar() {
	const dispatch = useAppDispatch()
	const activeTool = useAppSelector((s) => s.canvas.activeTool)
	const zoomLevel = useAppSelector((s) => s.canvas.zoomLevel)
	const showInterface = useAppSelector((s) => s.preferences.showInterface)

	useHotkeys('v', () => dispatch(setActiveTool('select')))
	useHotkeys('r', () => dispatch(setActiveTool('rectangle')))
	useHotkeys('t', () => dispatch(setActiveTool('text')))
	useHotkeys('z', () => dispatch(setActiveTool('zoom')))

	useHotkeys('meta+/', () => dispatch(toggleShowInterface()))

	// Utility shortcuts
	useHotkeys('shift+0', () => dispatch(resetCanvas()))
	useHotkeys('shift+1', () => dispatch(pressureTest()))

	const handleToolClick = (tool: ToolType) => {
		dispatch(setActiveTool(tool))
	}

	return (
		showInterface && (
			<div className='w-full h-full bg-white p-4 border-t border-zinc-200 flex gap-2 items-center'>
				<ToolButton
					isActive={activeTool === 'select'}
					onClick={() => handleToolClick('select')}
				>
					<MousePointer2 className='w-6 h-6' strokeWidth={1.5} />
				</ToolButton>
				<ToolButton
					isActive={activeTool === 'rectangle'}
					onClick={() => handleToolClick('rectangle')}
				>
					<Scan className='w-6 h-6' strokeWidth={1.5} />
				</ToolButton>
				<ToolButton
					isActive={activeTool === 'text'}
					onClick={() => handleToolClick('text')}
				>
					<Type className='w-6 h-6' strokeWidth={1.5} />
				</ToolButton>
				<ToolButton
					isActive={activeTool === 'zoom'}
					onClick={() => handleToolClick('zoom')}
				>
					<Search className='w-6 h-6' strokeWidth={1.5} />
				</ToolButton>
				<span className='grow' />
				{/* <Button variant='ghost'>Load</Button> */}
				<p className='font-medium text-zinc-500'>
					{Math.round(zoomLevel * 100)}%
				</p>
			</div>
		)
	)
}

function ToolButton(props: {
	onClick: () => void
	isActive: boolean
	children: React.ReactNode
}) {
	return (
		<button
			className={`p-2 rounded-lg ${
				props.isActive ? 'bg-blue-500 text-blue-50' : 'bg-white text-zinc-500'
			}`}
			onClick={props.onClick}
		>
			{props.children}
		</button>
	)
}

import { useEffect } from 'react'
import { useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { Input } from '@/components/ui/input'
import { Command } from 'cmdk'

export const CommandMenu = () => {
	const [open, setOpen] = useState(false)

	// Toggle the menu when ⌘K is pressed
	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				setOpen((open) => !open)
			}
		}

		document.addEventListener('keydown', down)
		return () => document.removeEventListener('keydown', down)
	}, [])

	return (
		<Dialog.Root open={open} onOpenChange={setOpen}>
			<Dialog.Content className='fixed left-1/2 top-2/3 z-50 grid w-full max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 border bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg'>
				<VisuallyHidden>
					<Dialog.Title>Command Menu</Dialog.Title>
					<Dialog.Description>
						Press ⌘K to search for commands.
					</Dialog.Description>
				</VisuallyHidden>

				<div className='p-2'>
					<Command label='Command Menu'>
						<CommandInput />

						<Command.List>
							<Command.Empty>No results found.</Command.Empty>

							<Command.Group>
								<CommandItem
									label='Button'
									onClick={() => {
										alert('Button')
									}}
								/>
								<CommandItem label='Text' onClick={() => {}} />
								<Command.Separator />
								<CommandItem label='Input' onClick={() => {}} />
							</Command.Group>

							<CommandItem label='Apple' onClick={() => {}} />
						</Command.List>
					</Command>
				</div>
			</Dialog.Content>
		</Dialog.Root>
	)
}

const CommandInput = () => {
	return (
		<Command.Input className='flex h-10 w-full border-b border-input bg-background p-4 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50' />
	)
}

const CommandItem = (props: { label: string; onClick: () => void }) => {
	return (
		<Command.Item onClick={props.onClick} className='p-4 rounded-md'>
			{props.label}
		</Command.Item>
	)
}

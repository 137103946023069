import EditorInput from '@/components/EditorInput'
import { PanelContainer } from './ui/PanelUI'

export default function DimensionPanel(props: {
	position: ElementDims
	onChangePosition: (position: ElementDims) => void
	canUpdatePosition: boolean
	canUpdateSize: boolean
}) {
	return (
		<PanelContainer>
			<div className='grid grid-cols-2 gap-x-2 gap-y-1'>
				<EditorInput.InputContainer>
					<EditorInput.InitialLetterLabel label='X' />
					<EditorInput.NumericInput
						value={props.position.left}
						onChange={(value) => {
							props.onChangePosition({
								...props.position,
								left: value,
							})
						}}
						disabled={!props.canUpdatePosition}
					/>
				</EditorInput.InputContainer>
				<EditorInput.InputContainer>
					<EditorInput.InitialLetterLabel label='Y' />
					<EditorInput.NumericInput
						value={props.position.top}
						onChange={(value) => {
							props.onChangePosition({
								...props.position,
								top: value,
							})
						}}
						disabled={!props.canUpdatePosition}
					/>
				</EditorInput.InputContainer>
				<EditorInput.InputContainer>
					<EditorInput.InitialLetterLabel label='W' />
					<EditorInput.NumericInput
						value={props.position.width}
						onChange={(value) => {
							props.onChangePosition({
								...props.position,
								width: value,
							})
						}}
						disabled={!props.canUpdateSize}
					/>
				</EditorInput.InputContainer>
				<EditorInput.InputContainer>
					<EditorInput.InitialLetterLabel label='H' />
					<EditorInput.NumericInput
						value={props.position.height}
						onChange={(value) => {
							props.onChangePosition({
								...props.position,
								height: value,
							})
						}}
						disabled={!props.canUpdateSize}
					/>
				</EditorInput.InputContainer>
			</div>
		</PanelContainer>
	)
}

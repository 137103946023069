import EditorInput from '@/components/EditorInput'
import { Minus, Plus } from 'lucide-react'
import ColorInput from './ColorInput'
import EditorIconButton from './ui/EditorIconButton'
import { useAppDispatch } from '@/lib/redux/hooks'
import {
	removeNodeEditorStyle,
	updateNodeEditorStyle,
} from '@/modules/content/contentSlice'
import { PanelContainer, PanelHeader } from './ui/PanelUI'

export default function BorderPanelContainer(props: { node: FrameCanvasNode }) {
	const dispatch = useAppDispatch()
	return (
		<BorderPanel
			node={props.node}
			onColorChange={(color: string) => {
				dispatch(
					updateNodeEditorStyle({
						nodeId: props.node.id,
						style: {
							border: {
								width: 1,
								color: {
									type: 'hex',
									value: color,
								},
							},
						},
					})
				)
			}}
			onOpacityChange={(opacity: number) => {
				throw new Error('Function not implemented.')
			}}
			onRemoveBackground={() => {
				dispatch(
					removeNodeEditorStyle({
						nodeId: props.node.id,
						editorStyleProp: 'border',
					})
				)
			}}
		/>
	)
}

function BorderPanel(props: {
	node: FrameCanvasNode
	onColorChange: (color: string) => void
	onOpacityChange: (opacity: number) => void
	onRemoveBackground: () => void
}) {
	return (
		<PanelContainer>
			<PanelHeader title='Border'>
				<EditorIconButton
					icon={<Plus size={16} />}
					disabled={!!props.node.style.border}
					onClick={() => {
						props.onColorChange('#CCC')
					}}
				/>
			</PanelHeader>
			{props.node.style.border && (
				<div className='flex flex-col gap-y-2'>
					<div className='flex items-center gap-x-2'>
						<ColorInput
							selectedColor={props.node.style.border?.color.value || '#CCC'}
							onColorSelected={props.onColorChange}
						/>
						<EditorInput.InputContainer>
							<EditorInput.NumericInput
								className='text-right'
								value={100}
								onChange={props.onOpacityChange}
							/>
							<EditorInput.InitialLetterLabel label='%' />
						</EditorInput.InputContainer>
						<EditorIconButton
							icon={<Minus size={16} />}
							onClick={props.onRemoveBackground}
						/>
					</div>
					<div className='flex items-center gap-x-2'>
						<EditorInput.InputContainer>
							<EditorInput.InitialLetterLabel label='W' />
							<EditorInput.NumericInput
								value={1}
								onChange={props.onOpacityChange}
							/>
						</EditorInput.InputContainer>
					</div>
				</div>
			)}
		</PanelContainer>
	)
}

'use client'

import LeftPanel from './ui/LeftPanel'
import Canvas, { CANVAS_ORIGIN } from '@/modules/canvas'
import RightPanel from './ui/RightPanel'
import Toolbar from './ui/Toolbar'
import { useRef, useEffect } from 'react'
import { CommandMenu } from './CommandMenu'

export default function Home() {
	const parentRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const parent = parentRef.current
		if (parent) {
			const scrollToCenter = () => {
				parent.scrollLeft = CANVAS_ORIGIN - 200
				parent.scrollTop = CANVAS_ORIGIN - 200
			}

			setTimeout(scrollToCenter, 0)
		}
	}, [])

	return (
		<div className='h-full w-full flex'>
			<CommandMenu />

			<LeftPanel />
			<div className='flex flex-col grow h-full overflow-hidden'>
				<div className='grow overflow-scroll' ref={parentRef}>
					<Canvas />
				</div>
				<div className=''>
					<Toolbar />
				</div>
			</div>
			<RightPanel />
		</div>
	)
}

import EditorInput from '@/components/EditorInput'
import { Minus, Plus } from 'lucide-react'
import ColorInput from './ColorInput'
import EditorIconButton from './ui/EditorIconButton'
import { useAppDispatch } from '@/lib/redux/hooks'
import {
	removeNodeEditorStyle,
	updateNodeEditorStyle,
} from '@/modules/content/contentSlice'
import { PanelContainer, PanelHeader } from './ui/PanelUI'

export default function BackgroundPanelContainer(props: {
	node: FrameCanvasNode
}) {
	const dispatch = useAppDispatch()
	return (
		<BackgroundPanel
			node={props.node}
			onColorChange={(color: string) => {
				dispatch(
					updateNodeEditorStyle({
						nodeId: props.node.id,
						style: {
							backgroundColor: {
								type: 'hex',
								value: color,
							},
						},
					})
				)
			}}
			onOpacityChange={(opacity: number) => {
				throw new Error('Function not implemented.')
			}}
			onRemoveBackground={() => {
				dispatch(
					removeNodeEditorStyle({
						nodeId: props.node.id,
						editorStyleProp: 'backgroundColor',
					})
				)
			}}
		/>
	)
}

function BackgroundPanel(props: {
	node: FrameCanvasNode
	onColorChange: (color: string) => void
	onOpacityChange: (opacity: number) => void
	onRemoveBackground: () => void
}) {
	return (
		<PanelContainer>
			<PanelHeader title='Background'>
				<EditorIconButton
					icon={<Plus size={16} />}
					disabled={!!props.node.style.backgroundColor}
					onClick={() => {
						props.onColorChange('#CCC')
					}}
				/>
			</PanelHeader>
			{props.node.style.backgroundColor && (
				<div className='flex items-center gap-x-2'>
					<ColorInput
						selectedColor={props.node.style.backgroundColor?.value || '#CCC'}
						onColorSelected={props.onColorChange}
					/>
					<EditorInput.InputContainer>
						<EditorInput.NumericInput
							className='text-right'
							value={100}
							onChange={props.onOpacityChange}
						/>
						<EditorInput.InitialLetterLabel label='%' />
					</EditorInput.InputContainer>
					<EditorIconButton
						icon={<Minus size={16} />}
						onClick={props.onRemoveBackground}
					/>
				</div>
			)}
		</PanelContainer>
	)
}

export default function EditorIconButton(props: {
	icon: React.ReactNode
	onClick: () => void
	label?: string
	disabled?: boolean
}) {
	return (
		<button
			className='px-2 py-2 text-sm font-medium flex items-center gap-x-1 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-muted rounded-sm'
			onClick={props.onClick}
			disabled={props.disabled}
		>
			{props.icon}
			{props.label && <span className=''>{props.label}</span>}
		</button>
	)
}

import EditorInput from '@/components/EditorInput'
import { PanelContainer } from './ui/PanelUI'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from './ui/EditorSelect'
import { FontSizeIcon, LineHeightIcon } from '@radix-ui/react-icons'

export default function TextStylePanel(props: {
	style: TextCanvasNode['style']
	onUpdateStyle: (style: TextCanvasNode['style']) => void
}) {
	return (
		<PanelContainer>
			{/* <Select>
				<SelectTrigger className=''>
					<SelectValue placeholder='Theme' />
				</SelectTrigger>
				<SelectContent>
					<SelectItem value='light'>Light</SelectItem>
					<SelectItem value='dark'>Dark</SelectItem>
					<SelectItem value='system'>System</SelectItem>
				</SelectContent>
			</Select> */}
			<div className='grid grid-cols-2 gap-x-2 gap-y-1'>
				<EditorInput.InputContainer>
					<EditorInput.InputLabel label={<FontSizeIcon strokeWidth={1.5} />} />
					<EditorInput.NumericInput
						value={props.style.fontSize}
						min={1}
						onChange={(value) => {
							props.onUpdateStyle({
								...props.style,
								fontSize: value,
							})
						}}
					/>
				</EditorInput.InputContainer>
				<EditorInput.InputContainer>
					<EditorInput.InputLabel
						label={<LineHeightIcon strokeWidth={1.5} />}
					/>
					<EditorInput.NumericInput
						value={props.style.lineHeight.value}
						min={0}
						step={0.1}
						onChange={(value) => {
							props.onUpdateStyle({
								...props.style,
								lineHeight: {
									type: props.style.lineHeight.type,
									value,
								},
							})
						}}
					/>
				</EditorInput.InputContainer>
			</div>
		</PanelContainer>
	)
}

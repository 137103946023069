import { AlignEndHorizontal } from 'lucide-react'

import { AlignCenterHorizontal } from 'lucide-react'

import { updateNodeRect } from '@/modules/content/contentSlice'
import { AlignStartHorizontal } from 'lucide-react'

import { AlignEndVertical } from 'lucide-react'

import { AlignCenterVertical, AlignStartVertical } from 'lucide-react'
import { useDispatch } from 'react-redux'
import EditorIconButton from './ui/EditorIconButton'
import { useAppSelector } from '@/lib/redux/hooks'
import { PanelContainer } from './ui/PanelUI'

export function AlignNodePanel(props: { nodeId: NodeId }) {
	const dispatch = useDispatch()
	const node = useAppSelector((s) => s.content.nodes[props.nodeId])
	const parentNode = useAppSelector(
		(s) => node?.parentId && s.content.nodes[node.parentId]
	)

	function updatePosition(position: Partial<ElementDims>) {
		dispatch(
			updateNodeRect({
				nodeId: props.nodeId,
				position,
			})
		)
	}

	return (
		parentNode &&
		'position' in node &&
		parentNode.type === 'frame' && (
			<PanelContainer>
				<div className='flex items-center gap-x-1 justify-evenly'>
					<EditorIconButton
						icon={<AlignStartVertical size={16} />}
						onClick={() => {
							updatePosition({ left: 0 })
						}}
					/>
					<EditorIconButton
						icon={<AlignCenterVertical size={16} />}
						onClick={() => {
							updatePosition({
								left: parentNode.position.width / 2 - node.position.width / 2,
							})
						}}
					/>
					<EditorIconButton
						icon={<AlignEndVertical size={16} />}
						onClick={() => {
							updatePosition({
								left: parentNode.position.width - node.position.width,
							})
						}}
					/>
					<span className='border-l border-zinc-200 h-4 shrink-0' />
					<EditorIconButton
						icon={<AlignStartHorizontal size={16} />}
						onClick={() => {
							updatePosition({ top: 0 })
						}}
					/>
					<EditorIconButton
						icon={<AlignCenterHorizontal size={16} />}
						onClick={() => {
							updatePosition({
								top: parentNode.position.height / 2 - node.position.height / 2,
							})
						}}
					/>
					<EditorIconButton
						icon={<AlignEndHorizontal size={16} />}
						onClick={() => {
							updatePosition({
								top: parentNode.position.height - node.position.height,
							})
						}}
					/>
				</div>
			</PanelContainer>
		)
	)
}
